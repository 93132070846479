import React from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"

import { mq } from "../utils/media-queries"

const PatientBio = ({ bio }) => {
  const { name, ageDiagnosed, photo, biography } = bio
  return (
    <article
      css={css`
        background: linear-gradient(180deg, #02d7f9 0%, #01aef1 100%);
        padding: 20px 35px 35px 20px;
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;
        ${[mq[0]]} {
          flex-direction: column;
        }
        h2,
        p {
          color: white;
        }
        h2 {
          font-size: 28px;
          font-weight: bold;
          line-height: 32px;
          text-transform: uppercase;
        }
        p {
          font-size: 18px;
          &:last-of-type {
            margin: 0;
          }
        }
      `}
    >
      <img
        loading='lazy'
        css={css`
          max-width: 168px;
          margin-right: 25px;
          ${[mq[0]]} {
            max-width: 100%;
            margin-bottom: 16px;
          }
        `}
        alt={`${name}`}
        src={photo.fixed.src}
      />
      <section css={css``}>
        <h2>{name}</h2>
        <p>Diagnosed at {ageDiagnosed}</p>
        <h2>BIO</h2>
        <p>{biography.biography}</p>
      </section>
    </article>
  )
}

PatientBio.propTypes = {
  bio: PropTypes.object.isRequired,
}

export default PatientBio
