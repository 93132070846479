import React from "react"
import { css } from "@emotion/core"
import PropTypes from "prop-types"

import { mq } from "../utils/media-queries"

const WatchNextVideo = ({ img, category, slug, isHome, nowViewing, alt }) => {
  return (
    <a
      id={`${isHome ? "home" : "videos"}_button_${slug.substring(1)}`}
      href={slug}
      css={css`
        display: block;
      `}
    >
      <section>
        <div
          css={css`
            display: inline-block;
            width: 100%;
          `}
        >
          <div
            css={css`
              position: absolute;
              z-index: 100;
              top: 0;
              right: 15px;
              bottom: 0;
              left: 15px;
              background-color: rgba(0, 0, 0, 0.4);
              width: calc(100% - 30px);
              height: 100%;
              transition: all .25s ease-out;
              &:hover {
                background-color: transparent;
              }
            `}
          />
          <img
            loading='lazy'
            css={css`
              width: 100%;
              height: auto;
              max-width: fill-available;
            `}
            alt={alt}
            src={img}
          />
        </div>
        {nowViewing ? (
          <div
            css={css`
              position: absolute;
              z-index: 200;
              top: 45%;
              right: 30px;
              left: 30px;
              display: flex;
              align-items: flex-end;
              justify-content: space-between;
            `}
          >
            <p
              css={css`
                margin: 0;
                width: 150px;
                color: white;
                font-size: 18px;
                font-weight: bold;
                line-height: 27px;
                text-shadow: 3px 5px 9px rgba(0, 0, 0, 0.82);
                text-transform: uppercase;
                ${[mq[2]]} {
                  font-size: 18px;
                }
              `}
            >
              Now Viewing
            </p>
          </div>
        ) : (
          ""
        )}
      </section>
    </a>
  )
}

WatchNextVideo.propTypes = {
  img: PropTypes.node.isRequired,
  category: PropTypes.string.isRequired,
}

export default WatchNextVideo
