import React from "react"
import { INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import questionsPdf from "../assets/downloadables/PS_Questions to Understand_HL.pdf"

/*
  Recommended way to render rich text
  Source: https://www.contentful.com/developers/docs/tutorials/general/rich-text-and-gatsby/
*/

const options = {
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: node => {
      return (
        <a
          // The commented code below worked initially, but there seems to have been an update
          // in contentful. For now, we'll assume the only links in the video asset description
          // will be for the Questions PDF
          // href={`http:${node.data.target.fields.file["en-US"].url}`}
          href={questionsPdf}
          target="_blank"
          rel="noopener noreferrer"
        >
          {node.content[0].value}
        </a>
      )
    },
  },
}

// VideoDescription.propTypes = {
//   json: PropTypes.object.isRequired,
// }

const VideoDescription = ({ json }) => {
  return documentToReactComponents(json, options)
}
export default VideoDescription
